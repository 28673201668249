import categoriesConfig from './categories.json';
import trendingSearchConfig from './trending_searches.json';
import homePageConfig from './home_screen.json';
import cartConfig from './cart.json';
import foodBeverageConfig from './food_beverage.json';
import healthSchool from './health_school.json';
// import brandsConfig from './shop_by_brands.json';
import healthNutritionConfig from './health_nutrition.json';
import boxCategoryConfig from './box-category.json';
import storiesConfig from './stories.json';

const navBarConfig = [
  {
    header_name: 'Categories',
    header_content: categoriesConfig,
    type: 'collections',
  },
  {
    header_name: 'Food & Beverages',
    header_content: foodBeverageConfig,
    type: 'collections',
  },
  {
    header_name: 'Health & Nutrition',
    header_content: healthNutritionConfig,
    type: 'collections',
  },
  {
    header_name: 'The Health School',
    header_content: healthSchool,
    type: 'blogs',
  },
];

export {
  navBarConfig,
  categoriesConfig,
  trendingSearchConfig,
  homePageConfig,
  cartConfig,
  boxCategoryConfig,
  storiesConfig,
};
